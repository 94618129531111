* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    -webkit-font-feature-settings: "kern" 1;
}

*:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}